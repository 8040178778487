const legal = {
	quickbooksTerms: "https://quickbooks.intuit.com/global/terms-of-service/",
	quickbooksPrivacy: "https://www.intuit.com/privacy/statement/",
	nachaRules: "https://www.nacha.org/newrules",
	aaa: "https://www.adr.org/index.php",
	googlePolicy: "https://policies.google.com/technologies/partner-sites",
	networkAdvertising: "https://optout.networkadvertising.org/?c=1",
	digitalAdvertising: "https://optout.aboutads.info/?c=2&lang=EN",
	onlineChoices: "https://youronlinechoices.eu/",
}

const routes = {
	home: "/",
	company: "/about",
	expert: "/experts/",
	terms: "/terms/",
	privacy: "/privacy/",
	blog: "/blog",
	faqs: "/experts/#faqs",
	details: "/experts/#details",
} as const

const social = {
	twitter: "https://twitter.com/flowfinance",
	linkedin: "https://www.linkedin.com/company/flowfi/",
} as const

const sections = {
	accounting: "/#accounting",
	cfo: "/#cfo",
	tax: "/#tax",
} as const

const email = {
	mailto: "mailto:info@flowfi.com",
	support: "mailto:support@flowfi.com",
} as const

const partners = {
	goldman: "https://www.goldmansachs.com/",
	bankOfAmerica: "https://bankofamerica.com/",
	northwestern: "https://www.northwestern.edu/",
	blackTux: "https://theblacktux.com/",
	vanderbuilt: "https://www.vanderbilt.edu/",
	paradeVentures: "https://www.parade.vc",
	specialInvest: "https://www.special.co",
	precursorVentures: "https://precursorvc.com",
	blumbergCapital: "https://blumbergcapital.com",
	cooley: "https://www.cooley.com/",
	clocktowerVentures: "https://www.clocktowerventures.com/",
	differentialVentures: "https://differential.vc",
} as const

const customers = {
	lifeforce: "https://www.mylifeforce.com/",
	baton: "https://baton.io",
	move: "https://move.one",
	arkive: "https://arkive.net",
	girlSkateBoarding: "https://girlskateboards.com",
	prescient: "https://www.prescient-ai.io/",
	loved: "https://loved01.com/",
	tinted: "https://www.livetinted.com/",
	beagle: "https://www.beagleservices.com/",
	nuveldys: "https://nuveldys.com/",
	flamingo: "https://flamingoestate.com/",
} as const

const links = {
	...routes,
	...social,
	...sections,
	...customers,
	...legal,
	...email,
	...partners,
	signIn: "https://app.flowfi.com/signin",
	signUp: "https://app.flowfi.com/signup",
	careers: "https://www.linkedin.com/company/flowfi/jobs",
	apply: "https://www.app.flowfi.com/experts",
	demo: "/book-demo",
} as const

export default links
